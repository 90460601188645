let modules = {
    // ControlBase Stuff
    Control: 'Controls/control',
    Control_Central: 'Controls/control+central',

    // Command Src
    ControlCommandSrc: 'Controls/controlCommandSrc',

    ControlActionBaseScreen: 'Controls/controlActionBaseScreen',
    ControlActionScreen: 'Controls/controlActionScreen',
    ControlActionCellsScreen: 'Controls/controlActionCellsScreen',
    ControlActionSliderScreen: 'Controls/controlActionSliderScreen',
    ControlActionCardsScreen: 'Controls/controlActionCardsScreen',
    ControlContentLockedScreen: 'Controls/controlContentLockedScreen',

    // ControlInfoView
    ControlInfoView: 'Controls/controlInfoView',

    UniversalControl: 'Controls/universalControl/universalControl',
    UniversalControlContent: 'Controls/universalControl/content/controlContent',
    UniversalControlStatesSrc: 'Controls/universalControl/universalControlStatesSrc',
    UniversalControlValueScreen: 'Controls/universalControl/content/valueScreen',
    UniversalControlInfoView: 'Controls/universalControl/content/infoView/universalControlInfoView',
    UniversalControlSendCommandInfoView: 'Controls/universalControl/content/sendCmdInfoView/sendCmdInfoView',

    // AtheneControl
    AtheneControl: 'Controls/atheneControl/atheneControl',
    AtheneControlEnums: 'Controls/atheneControl/atheneControlEnums',
    AtheneMediaHandler: 'Controls/atheneControl/webRTC/AtheneMediaHandler',
    ActivityImageCache: 'Controls/atheneControl/helper/ActivityImageCache',
    ActivityImageQueue: 'Controls/atheneControl/helper/ActivityImageQueue',
    ActivityImage: 'Controls/atheneControl/helper/ActivityImage',
    SignalingBase: 'Controls/atheneControl/webRTC/signaling/signalingBase',
    WebsocketSignaling: 'Controls/atheneControl/webRTC/signaling/websocketSignaling',
    AtheneControlContentBase: 'Controls/atheneControl/content/controlContentBase',
    AtheneControlContentUnsupported: 'Controls/atheneControl/content/controlContentUnsupported',
    AtheneControlContent: 'Controls/atheneControl/content/controlContent',
    AtheneControlCommandSrc: 'Controls/atheneControl/atheneControlCommandSrc',
    AtheneVideoView: 'Controls/atheneControl/content/videoView/atheneVideoView',
    AtheneSettingsScreen: 'Controls/atheneControl/content/screens/atheneSettings/atheneSettingsScreen',
    AtheneDiagnosisScreen: 'Controls/atheneControl/content/screens/atheneSettings/diagnosis/diagnosisScreen',
    AtheneActivityDetailsScreen: 'Controls/atheneControl/content/screens/atheneActivityDetails/atheneActivityDetailsScreen',
    AtheneActivitiesScreen: 'Controls/atheneControl/content/screens/atheneActivities/atheneActivitiesScreen',
    AtheneNotReadyScreen: 'Controls/atheneControl/content/screens/atheneNotReady/atheneNotReady',

    // AlarmClock
    AlarmClockControl: 'Controls/alarmClockControl/alarmClockControl',
    AlarmClockControlEnums: 'Controls/alarmClockControl/alarmClockControlEnums',
    AlarmClockControlCommandSrc: 'Controls/alarmClockControl/alarmClockControlCommandSrc',
    AlarmClockControlStatesSrc: 'Controls/alarmClockControl/alarmClockControlStatesSrc',
    AlarmClockControlContent: 'Controls/alarmClockControl/content/controlContent',

    // AlarmBase
    AlarmControlContentBase: 'Controls/alarmControlBase/content/controlContent',

    // Alarm
    AlarmControl: 'Controls/alarmControl/alarmControl',
    AlarmControlEnums: 'Controls/alarmControl/alarmControlEnums',
    AlarmControlCommandSrc: 'Controls/alarmControl/alarmControlCommandSrc',
    AlarmControlStatesSrc: 'Controls/alarmControl/alarmControlStatesSrc',
    AlarmTrackerView: 'Controls/alarmControl/content/trackerView/trackerView',
    AlarmControlInfoView: 'Controls/alarmControl/content/infoView/alarmControlInfoView',
    AlarmCentralControl: 'Controls/alarmCentralControl/alarmCentralControl',
    AlarmCentralControlCommandSrc: 'Controls/alarmCentralControl/alarmCentralControlCommandSrc',

    // AAL Smart Alarm
    AalSmartAlarmControl: 'Controls/aalSmartAlarmControl/aalSmartAlarmControl',
    AalSmartAlarmControlEnums: 'Controls/aalSmartAlarmControl/aalSmartAlarmControlEnums',
    AalSmartAlarmControlCommandSrc: 'Controls/aalSmartAlarmControl/aalSmartAlarmControlCommandSrc',
    AalSmartAlarmControlStatesSrc: 'Controls/aalSmartAlarmControl/aalSmartAlarmControlStatesSrc',
    AalSmartAlarmControlContent: 'Controls/aalSmartAlarmControl/content/controlContent',

    //Application/ Webpage
    ApplicationControl: 'Controls/applicationControl/applicationControl',
    ApplicationControlCommandSrc: 'Controls/applicationControl/applicationControlCommandSrc',

    // AudioZone
    AudioZoneControl: 'Controls/audioZoneControl/audioZoneControl',
    AudioZoneControlEnums: 'Controls/audioZoneControl/audioZoneControlEnums',
    AudioZoneControlContent: 'Controls/audioZoneControl/content/controlContent',
    AudioZoneControlContentHD: 'Controls/audioZoneControl/content/controlContentHD',
    AudioZoneControlContentRemote: 'Controls/audioZoneControl/content/controlContentRemote',
    AudioZoneNotReadyScreen: 'Controls/audioZoneControl/content/screens/notReady/notReadyScreen',
    AudioZoneControlContentHomeScreen: 'Controls/audioZoneControl/content/screens/detailed/home/homeScreen',
    AudioZoneCentralControl: 'Controls/audioZoneCentralControl/audioZoneCentralControl',
    AudioZoneCentralControlContent: 'Controls/audioZoneCentralControl/content/controlContent',
    AudioZoneCentralControlCommandSrc: 'Controls/audioZoneCentralControl/audioZoneCentralControlCommandSrc',
    AudioZoneTableViewHelper: 'Controls/audioZoneControl/content/helper/audioZoneTableViewHelper',
    AudioZoneSpotifyHelper: 'Controls/audioZoneControl/content/helper/audioZoneSpotifyHelper',
    AudioZoneShortcutHelper: 'Controls/audioZoneControl/content/helper/audioZoneShortcutHelper',
    CustomizationHelper: 'Controls/audioZoneControl/content/helper/customizationHelper',
    SpotifyAccountHelper: 'Controls/audioZoneControl/content/helper/spotifyAccountHelper',
    // __ Adopted when changing to new structure
    HardwareVolumeButtonHandler: 'Controls/audioZoneControl/content/helper/hardwareVolumeButtonHandler',

    // ControlContent
    TinyPlayer: 'Controls/audioZoneControl/content/views/tinyPlayer/tinyPlayer',
    TinyPlayerHD: 'Controls/audioZoneControl/content/views/tinyPlayer/tinyPlayerHD',
    CoverCarouselView: 'Controls/audioZoneControl/content/views/coverView/coverView',
    CoverView: 'Controls/audioZoneControl/content/views/coverView/coverView',
    CurrentCoverView: 'Controls/audioZoneControl/content/views/coverView/currentCover/currentCoverView',
    TimeLineSlider: 'Controls/audioZoneControl/content/views/timeLineSlider/timeLineSlider',
    AudioZoneControlQueueScreen: 'Controls/audioZoneControl/content/views/queue/queueScreen',

    AudioZoneControlPlayer: 'Controls/audioZoneControl/content/screens/player/audioZoneControlPlayer',

    ZoneGroupOverviewScreen: 'Controls/audioZoneControl/content/screens/detailed/grouping/zoneGroupOverviewScreen',
    ZoneGroupScreen: 'Controls/audioZoneControl/content/screens/detailed/grouping/zoneGroupScreen',
    ZoneGroupCreateEditScreen: 'Controls/audioZoneControl/content/screens/detailed/grouping/zoneGroupCreateEditScreen',
    AudioZoneDetailedContentBase: 'Controls/audioZoneControl/content/screens/detailed/detailedContentBase',
    ZoneVoiceRecorder: 'Controls/audioZoneControl/content/screens/detailed/voiceRecorder/voiceRecorder',
    MediaSearchBase: 'Controls/audioZoneControl/content/screens/search/searchScreenBase',
    MediaLegacySearchScreen: 'Controls/audioZoneControl/content/screens/search/legacySearch/legacySearchScreen',
    MediaGlobalSearch: 'Controls/audioZoneControl/content/screens/search/globalSearch/globalSearchScreen',
    MediaBrowserBase: 'Controls/audioZoneControl/content/screens/detailed/mediaBrowser/mediaBrowserBase',
    MediaBrowserService: 'Controls/audioZoneControl/content/screens/detailed/mediaBrowser/mediaBrowser_Service',
    MediaBrowserService7: 'Controls/audioZoneControl/content/screens/detailed/mediaBrowser/mediaBrowser_Service-7',
    MediaBrowserService11: 'Controls/audioZoneControl/content/screens/detailed/mediaBrowser/mediaBrowser_Service-11',
    MediaBrowserService12: 'Controls/audioZoneControl/content/screens/detailed/mediaBrowser/mediaBrowser_Service-12',
    MediaBrowserFavorites: 'Controls/audioZoneControl/content/screens/detailed/mediaBrowser/mediaBrowser_Favorites',
    MediaBrowserPlaylists: 'Controls/audioZoneControl/content/screens/detailed/mediaBrowser/mediaBrowser_Playlists',
    MediaBrowserLineIn: 'Controls/audioZoneControl/content/screens/detailed/mediaBrowser/mediaBrowser_LineIn',
    MediaBrowserLibrary: 'Controls/audioZoneControl/content/screens/detailed/mediaBrowser/mediaBrowser_Library',
    SpotifyLibraryScreen: 'Controls/audioZoneControl/content/screens/detailed/spotify/spotifyLibrary',

    AudioZoneControlSettings: 'Controls/audioZoneControl/content/screens/settings/audioZoneControlSettings',
    AudioZoneControlAboutMediaServer: 'Controls/audioZoneControl/content/screens/settings/about/audioZoneControlAboutMediaServer',
    AudioZoneSettingsAirplay: 'Controls/audioZoneControl/content/screens/settings/airplay/audioZoneSettingsAirplay',
    AudioZoneSettingsSpotifyManagementScreen: 'Controls/audioZoneControl/content/screens/settings/services/spotify/spotifyManagementScreen',
    AudioZoneSettingsServiceLoginScreen: 'Controls/audioZoneControl/content/screens/settings/services/serviceLoginScreen',
    AudioZoneSettingsServiceRadioSettings: 'Controls/audioZoneControl/content/screens/settings/services/tunein/tuneinManagementScreen',
    AudioZoneControlSettingsLineInList: 'Controls/audioZoneControl/content/screens/settings/lineIn/audioZoneControlSettingsLineInList',
    AudioZoneControlSettingsLineIn: 'Controls/audioZoneControl/content/screens/settings/lineIn/audioZoneControlSettingsLineIn',
    AudioZoneControlZoneEQSettings: 'Controls/audioZoneControl/content/screens/settings/zone/zoneEQSettings',
    AudioZoneControlZoneAudioDelaySettings: 'Controls/audioZoneControl/content/screens/settings/zone/audioDelaySettingsScreen',
    AudioZoneControlZoneVolumeSettings: 'Controls/audioZoneControl/content/screens/settings/zone/volumeSettings',
    AudioZoneControlSettingsLibrary: 'Controls/audioZoneControl/content/screens/settings/library/librarySettings',
    AudioZoneControlSettingsLibraryAddOrEdit: 'Controls/audioZoneControl/content/screens/settings/library/libraryAddOrEditScreen',
    AudioZoneControlSettingsLibraryOverview: 'Controls/audioZoneControl/content/screens/settings/library/libraryOverviewScreen',
    AudioZoneControlSettingsShortcutEditScreen: 'Controls/audioZoneControl/content/screens/settings/shortcut/shortcutEditScreen',
    AudioZoneControlAppearanceEditScreen: 'Controls/audioZoneControl/content/screens/settings/appearanceEdit/appearanceEditScreen',

    AudioZoneControlFavoriteEdit: "Controls/audioZoneControl/content/screens/settings/favorites/zoneFavorites/favoriteEditScreen",
    AudioZoneControlGlobalFavoriteEdit: "Controls/audioZoneControl/content/screens/settings/favorites/globalFavorites/globalFavoriteEditScreen",

    AddMediaViewControllerBase: 'Controls/audioZoneControl/content/screens/editOrAddMedia/viewController/addMediaViewControllerBase',
    AddMediaViewControllerZoneFavorite: 'Controls/audioZoneControl/content/screens/editOrAddMedia/viewController/addMediaViewControllerZoneFavorite',
    AddMediaViewControllerPlaylist: 'Controls/audioZoneControl/content/screens/editOrAddMedia/viewController/addMediaViewControllerPlaylist',
    AddMediaViewControllerPlaylistPicker: 'Controls/audioZoneControl/content/screens/editOrAddMedia/viewController/addMediaViewControllerPlaylistPicker',
    AddMediaViewControllerPlaylistSpotify: 'Controls/audioZoneControl/content/screens/editOrAddMedia/viewController/addMediaViewControllerPlaylistSpotify',
    AddMediaScreen: 'Controls/audioZoneControl/content/screens/editOrAddMedia/addMediaScreen',
    AddZoneFavoritesScreen: 'Controls/audioZoneControl/content/screens/editOrAddMedia/addFavScreen',

    EditMediaScreen: 'Controls/audioZoneControl/content/screens/editOrAddMedia/editMediaScreen',


    MediaContextMenuBase: 'Controls/audioZoneControl/content/screens/mediaContextMenu/mediaContextMenuBase',

    // AudioPlayerGroup
    AudioPlayerGroupControl: 'Controls/audioPlayerGroupControl/audioPlayerGroupControl',

    // AudioZoneV2
    AudioZoneV2Control: 'Controls/audioZoneV2Control/audioZoneV2Control',
    AudioZoneV2ControlEnums: 'Controls/audioZoneV2Control/audioZoneV2ControlEnums',
    AudioZoneV2ControlContent: 'Controls/audioZoneV2Control/content/controlContent',
    AudioZoneV2ControlContentHD: 'Controls/audioZoneV2Control/content/controlContentHD',
    AudioZoneV2SearchInfoView: 'Controls/audioZoneV2Control/content/infoView/searchInfoView',
    // AudioZoneV2 Helper
    ControlContentMenu: 'Controls/audioZoneV2Control/content/helper/controlContentMenu',
    CustomizationManager: 'Controls/audioZoneV2Control/content/helper/customizationManager',
    FavoritesManager: 'Controls/audioZoneV2Control/content/helper/favoritesManager',
    SpotifyAccountManager: 'Controls/audioZoneV2Control/content/helper/spotifyAccountManager',
    SoundsuitAccountManager: 'Controls/audioZoneV2Control/content/helper/soundsuitAccountManager',
    HistoryManager: 'Controls/audioZoneV2Control/content/helper/historyManager',
    HardwareVolumeButtonHandlerV2: 'Controls/audioZoneV2Control/content/helper/hardwareVolumeButtonHandler',
    OverviewContentLoader: 'Controls/audioZoneV2Control/content/helper/overviewContentLoader',
    // AudioZoneV2 Screens
    AudioZoneV2NotReadyScreen: 'Controls/audioZoneV2Control/content/screens/notReady/notReadyScreen',
    AudioZoneV2DetailedContentBase: 'Controls/audioZoneV2Control/content/screens/detailed/detailedContentBase',
    ContentOverviewScreenBase: 'Controls/audioZoneV2Control/content/screens/detailed/overview/overviewBase',
    RadioContentOverviewScreen: 'Controls/audioZoneV2Control/content/screens/detailed/overview/radioOverview',
    PlaylistsContentOverviewScreen: 'Controls/audioZoneV2Control/content/screens/detailed/overview/playlistsOverview',
    SpotifyContentOverviewScreen: 'Controls/audioZoneV2Control/content/screens/detailed/overview/spotifyOverview',
    SoundsuitContentOverviewScreen: 'Controls/audioZoneV2Control/content/screens/detailed/overview/soundsuitOverview',
    LineInContentOverviewScreen: 'Controls/audioZoneV2Control/content/screens/detailed/overview/lineInOverview',
    StartOverviewScreen: 'Controls/audioZoneV2Control/content/screens/detailed/overview/startOverview',
    HistoryOverviewScreen: 'Controls/audioZoneV2Control/content/screens/detailed/overview/historyOverview',
    MediaBrowserV2Base: 'Controls/audioZoneV2Control/content/screens/detailed/mediaBrowser/mediaBrowserBase',
    MediaBrowserV2Service: 'Controls/audioZoneV2Control/content/screens/detailed/mediaBrowser/mediaBrowser_Service',
    MediaBrowserV2Service7: 'Controls/audioZoneV2Control/content/screens/detailed/mediaBrowser/mediaBrowser_Service-7',
    MediaBrowserV2Service11: 'Controls/audioZoneV2Control/content/screens/detailed/mediaBrowser/mediaBrowser_Service-11',
    MediaBrowserV2Service12: 'Controls/audioZoneV2Control/content/screens/detailed/mediaBrowser/mediaBrowser_Service-12',
    MediaBrowserV2Favorites: 'Controls/audioZoneV2Control/content/screens/detailed/mediaBrowser/mediaBrowser_Favorites',
    MediaBrowserV2LineIn: 'Controls/audioZoneV2Control/content/screens/detailed/mediaBrowser/mediaBrowser_LineIn',
    MediaBrowserV2Library: 'Controls/audioZoneV2Control/content/screens/detailed/mediaBrowser/mediaBrowser_Library',
    MediaBrowserV2Playlists: 'Controls/audioZoneV2Control/content/screens/detailed/mediaBrowser/mediaBrowser_Playlists',
    SpotifyLibraryScreenV2: 'Controls/audioZoneV2Control/content/screens/detailed/spotify/spotifyLibrary',
    MediaContextMenuV2Base: 'Controls/audioZoneV2Control/content/screens/mediaContextMenu/mediaContextMenuBase',
    FavoriteEditScreen: 'Controls/audioZoneV2Control/content/screens/detailed/favEdit/favEditScreen',
    EditSpecificFavScreen: 'Controls/audioZoneV2Control/content/screens/detailed/favEdit/editSpecificFavScreen',
    SelectorScreenWrapper: 'Controls/audioZoneV2Control/content/screens/selectorWrapper',
    MediaSearchV2Base: 'Controls/audioZoneV2Control/content/screens/search/searchScreenBase',
    MediaGlobalSearchV2: 'Controls/audioZoneV2Control/content/screens/search/globalSearch/globalSearchScreen',
    AddMediaViewControllerV2Base: 'Controls/audioZoneV2Control/content/screens/editOrAddMedia/viewController/addMediaViewControllerBase',
    AddMediaViewControllerV2ZoneFavorite: 'Controls/audioZoneV2Control/content/screens/editOrAddMedia/viewController/addMediaViewControllerZoneFavorite',
    AddMediaViewControllerV2Playlist: 'Controls/audioZoneV2Control/content/screens/editOrAddMedia/viewController/addMediaViewControllerPlaylist',
    AddMediaViewControllerV2PlaylistPicker: 'Controls/audioZoneV2Control/content/screens/editOrAddMedia/viewController/addMediaViewControllerPlaylistPicker',
    AddMediaViewControllerV2PlaylistSpotify: 'Controls/audioZoneV2Control/content/screens/editOrAddMedia/viewController/addMediaViewControllerPlaylistSpotify',
    AddMediaViewControllerV2SpotifyOnboarding: 'Controls/audioZoneV2Control/content/screens/editOrAddMedia/viewController/addMediaViewControllerSpotifyOnboarding',
    AddMediaV2Screen: 'Controls/audioZoneV2Control/content/screens/editOrAddMedia/addMediaScreen',
    EditMediaScreenV2: 'Controls/audioZoneV2Control/content/screens/editOrAddMedia/editMediaScreen',
    SpotifyOnboardingScreen: 'Controls/audioZoneV2Control/content/screens/editOrAddMedia/spotifyOnboardingScreen',
    RecordingSelection: 'Controls/audioZoneV2Control/content/screens/detailed/voiceRecorder/recordingSelection',
    RecordingScreen: 'Controls/audioZoneV2Control/content/screens/detailed/voiceRecorder/recording',
    CustomStreamScreen: 'Controls/audioZoneV2Control/content/screens/detailed/customStream/customStreamScreen',
    ZoneGroupOverviewV2: 'Controls/audioZoneV2Control/content/screens/detailed/grouping/groupScreen',
    PlayerV2: 'Controls/audioZoneV2Control/content/screens/player/player',
    // Settings
    MusicServerSettings: 'Controls/audioZoneV2Control/content/screens/settings/settings',
    AboutAudioServer: 'Controls/audioZoneV2Control/content/screens/settings/about/aboutAudioServer',
    AudioserverDiagnosis: 'Controls/audioZoneV2Control/content/screens/settings/about/diagnosis/diagnosis',
    AirPlaySettings: 'Controls/audioZoneV2Control/content/screens/settings/airplay/audioZoneSettingsAirplay',
    SpotifyManagement: 'Controls/audioZoneV2Control/content/screens/settings/services/spotify/spotifyManagementScreen',
    NewSpotifyAccountScreen: 'Controls/audioZoneV2Control/content/screens/settings/services/spotify/newAccountScreen',
    SoundsuitManagement: 'Controls/audioZoneV2Control/content/screens/settings/services/soundsuit/soundsuitManagementScreen',
    SoundsuitAccountScreen: 'Controls/audioZoneV2Control/content/screens/settings/services/soundsuit/soundsuitAccountScreen',
    ServiceLoginScreen: 'Controls/audioZoneV2Control/content/screens/settings/services/serviceLoginScreen',
    ServiceRadioSettings: 'Controls/audioZoneV2Control/content/screens/settings/services/tunein/tuneinManagementScreen',
    AudioInputList: 'Controls/audioZoneV2Control/content/screens/settings/lineIn/audioInputList',
    SettingsLineIn: 'Controls/audioZoneV2Control/content/screens/settings/lineIn/audioZoneControlSettingsLineIn',
    EQSettings: 'Controls/audioZoneV2Control/content/screens/settings/zone/zoneEQSettings',
    VolumeSettings: 'Controls/audioZoneV2Control/content/screens/settings/zone/volumeSettings',
    BluetoothSettings: 'Controls/audioZoneV2Control/content/screens/settings/zone/bluetoothSettings',
    SettingsLibrary: 'Controls/audioZoneV2Control/content/screens/settings/library/librarySettings',
    SettingsLibraryAddOrEdit: 'Controls/audioZoneV2Control/content/screens/settings/library/libraryAddOrEditScreen',
    SettingsLibraryOverview: 'Controls/audioZoneV2Control/content/screens/settings/library/libraryOverviewScreen',
    FavoriteEdit: "Controls/audioZoneV2Control/content/screens/settings/favorites/zoneFavorites/favoriteEditScreen",
    // Views
    CoverViewV2: 'Controls/audioZoneV2Control/content/views/coverView/coverView',
    CurrentCoverViewV2: 'Controls/audioZoneV2Control/content/views/coverView/currentCover/currentCoverView',
    QueueScreenV2: 'Controls/audioZoneV2Control/content/views/queue/queueScreen',
    EcoPlayerView: 'Controls/audioZoneV2Control/content/views/ecoPlayerView/ecoPlayerView',

    // CarCharger
    CarChargerControl: 'Controls/carChargerControl/carChargerControl',
    CarChargerControlEnums: 'Controls/carChargerControl/carChargerControlEnums',
    CarChargerControlCommandSrc: 'Controls/carChargerControl/carChargerControlCommandSrc',
    CarChargerControlStatesSrc: 'Controls/carChargerControl/carChargerControlStatesSrc',
    CarChargerControlContentSettings: 'Controls/carChargerControl/content/settingsScreen/carChargerControlContentSettings',

    // ClimateController
    ClimateControl: 'Controls/climateControl/climateControl',
    ClimateControlEnums: 'Controls/climateControl/climateControlEnums',
    ClimateControlCommandSrc: 'Controls/climateControl/climateControlCommandSrc',
    ClimateControlStatesSrc: 'Controls/climateControl/climateControlStatesSrc',
    ClimateControlContent: 'Controls/climateControl/content/controlContent',
    ClimateControlContentSettings: 'Controls/climateControl/content/settingsScreen/settingsScreen',
    ClimateControlTemperaturesScreen: 'Controls/climateControl/content/settingsScreen/modeScreen/temperaturesScreen',

    // ClimateControllerUs
    ClimateUsControl: 'Controls/climateUsControl/climateUsControl',
    ClimateUsControlEnums: 'Controls/climateUsControl/climateUsControlEnums',
    ClimateUsControlCommandSrc: 'Controls/climateUsControl/climateUsControlCommandSrc',
    ClimateUsControlContent: 'Controls/climateUsControl/content/controlContent',
    ClimateUsControlIrcList: 'Controls/climateUsControl/content/ircListScreen/ircListScreen',
    ClimateUsControlModeSelectorScreen: 'Controls/climateUsControl/content/climateUsModeScreen/climateUsModeScreen',
    ClimateUsControlOutsideTempScreen: 'Controls/climateUsControl/content/tempLimitsScreen/tempLimitsScreen',
    ClimateUsInfoView: 'Controls/climateUsControl/content/views/climateUsInfoView',

    TempLimitsView: 'Controls/climateUsControl/content/tempLimitsScreen/tempLimitsView',

    // ColorPicker
    ColorPickerControl: 'Controls/colorPickerControl/colorPickerControl',
    ColorPickerControlContent: 'Controls/colorPickerControl/content/controlContent',
    ColorPickerControlEnums: 'Controls/colorPickerControl/colorPickerControlEnums',
    ColorPickerColorSelection: 'Controls/colorPickerControl/content/views/colorSelection',

    // ColorPickerV2
    ColorPickerV2Control: 'Controls/colorPickerV2Control/colorPickerV2Control',
    ColorPickerV2ControlContent: 'Controls/colorPickerV2Control/content/controlContent',
    ColorPickerV2ControlEnums: 'Controls/colorPickerV2Control/colorPickerV2ControlEnums',
    ColorSelectorPage: 'Controls/colorPickerV2Control/content/selectorPage/colorSelectorPage',
    SequenceSelectorPage: 'Controls/colorPickerV2Control/content/selectorPage/sequenceSelectorPage',
    SelectorPageBase: 'Controls/colorPickerV2Control/content/selectorPage/selectorPageBase',
    ColorPreviewBar: 'Controls/colorPickerV2Control/content/views/colorPreviewBar',
    FavoriteSelectorBase: 'Controls/colorPickerV2Control/content/views/favoriteSelectorBase',
    FavColorButton: 'Controls/colorPickerV2Control/content/views/favColorButton',
    ColorSelectorV2: 'Controls/colorPickerV2Control/content/views/colorSelectorV2',

    // SmokeAlarm
    SmokeAlarmControl: 'Controls/smokeAlarmControl/smokeAlarmControl',
    SmokeAlarmControlStatesSrc: 'Controls/smokeAlarmControl/smokeAlarmControlStatesSrc',
    SmokeAlarmControlCommandSrc: 'Controls/smokeAlarmControl/smokeAlarmControlCommandSrc',
    SmokeAlarmControlContent: 'Controls/smokeAlarmControl/content/controlContent',
    SmokeAlarmControlContentServiceMode: 'Controls/smokeAlarmControl/content/serviceMode/serviceModeScreen',
    SmokeAlarmControlEnums: 'Controls/smokeAlarmControl/smokeAlarmControlEnums',

    // Dimmer
    DimmerControl: 'Controls/dimmerControl/dimmerControl',
    DimmerControlContent: 'Controls/dimmerControl/content/controlContent',
    DimmerControlCommandSrc: 'Controls/dimmerControl/dimmerControlCommandSrc',

    // Fronius
    FroniusControl: 'Controls/froniusControl/froniusControl',
    FroniusControlEnums: 'Controls/froniusControl/froniusControlEnums',
    FroniusControlContent: 'Controls/froniusControl/content/controlContent',
    FroniusControlStatesSrc: 'Controls/froniusControl/froniusControlStatesSrc',
    FroniusAnimationView: 'Controls/froniusControl/content/infoView/views/froniusAnimationView',
    EnergyMonitorBattAnimationView: 'Controls/froniusControl/content/infoView/views/energyMonitorBattAnimationView',
    FroniusControlInfoView: 'Controls/froniusControl/content/infoView/froniusControlInfoView',
    FroniusControlContentOverview: 'Controls/froniusControl/content/froniusOverview/froniusControlContentOverview',

    // Gate
    GateControl: 'Controls/gateControl/gateControl',
    GateControlCommandSrc: 'Controls/gateControl/gateControlCommandSrc',
    GateControlEnums: 'Controls/gateControl/gateControlEnums',
    GateCentralControl: 'Controls/gateCentralControl/gateCentralControl',
    GateCentralControlCommandSrc: 'Controls/gateCentralControl/gateCentralControlCommandSrc',

    // Heatmixer
    HeatmixerControl: 'Controls/heatmixerControl/heatmixerControl',
    HeatmixerControlStatesSrc: 'Controls/heatmixerControl/heatmixerControlStatesSrc',

    // HourCounter
    HourcounterControl: 'Controls/hourcounterControl/hourcounterControl',
    HourcounterControlCommandSrc: 'Controls/hourcounterControl/hourcounterControlCommandSrc',
    HourcounterControlStatesSrc: 'Controls/hourcounterControl/hourcounterControlStatesSrc',
    HourcounterControlContent: 'Controls/hourcounterControl/content/controlContent',
    HourcounterControlInfoView: 'Controls/hourcounterControl/content/infoView/hourcounterControlInfoView',
    TimeDisplay: 'Controls/hourcounterControl/views/timeDisplay',

    // InfoView
    InfoViewControl: 'Controls/infoViewControl/infoViewControl',
    InfoViewControlStatesSrc: 'Controls/infoViewControl/infoViewControlStatesSrc',

    // LightController
    LightControl: 'Controls/lightControl/lightControl',
    LightControlCommandSrc: 'Controls/lightControl/lightControlCommandSrc',
    LightControlEnums: 'Controls/lightControl/lightControlEnums',
    LightControlContent: 'Controls/lightControl/content/controlContent',
    LightControlContentOld: 'Controls/lightControl/contentOld/lightControlContentOld',
    LightControlContentOldHD: 'Controls/lightControl/contentOld/lightControlContentOldHD',
    LightControlEditSceneScreen: 'Controls/lightControl/contentOld/screens/editSceneScreen',
    LightControlSaveSceneScreen: 'Controls/lightControl/contentOld/screens/saveSceneScreen',
    LightControlManualList: 'Controls/lightControl/contentOld/views/manualList',
    LightControlSceneList: 'Controls/lightControl/contentOld/views/sceneList',
    LightCentralControl: 'Controls/lightCentralControl/lightCentralControl',
    LightCentralControlContent: 'Controls/lightCentralControl/content/controlContent',
    LightCentralControlCommandSrc: 'Controls/lightCentralControl/lightCentralControlCommandSrc',

    // LightsceneRGB
    LightsceneRGBControl: 'Controls/lightsceneRGBControl/lightsceneRGBControl',
    LightsceneRGBControlCommandSrc: 'Controls/lightsceneRGBControl/lightsceneRGBControlCommandSrc',
    LightsceneRGBControlContent: 'Controls/lightsceneRGBControl/content/controlContent',
    LightsceneRGBControlContentOld: 'Controls/lightsceneRGBControl/contentOld/lightsceneRGBControlContent',
    LightsceneRGBControlContentOldHD: 'Controls/lightsceneRGBControl/contentOld/lightsceneRGBControlContentHD',

    // LightV2Controller
    LightV2Control: 'Controls/lightV2Control/lightV2Control',
    LightV2ControlCommandSrc: 'Controls/lightV2Control/lightV2ControlCommandSrc',
    LightV2ControlEnums: 'Controls/lightV2Control/lightV2ControlEnums',
    LightV2ControlContent: 'Controls/lightV2Control/content/controlContent',
    LightV2ControlContentOld: 'Controls/lightV2Control/contentOld/lightV2ControlContentOld',
    LightV2ControlContentOldHD: 'Controls/lightV2Control/contentOld/lightV2ControlContentOldHD',
    LightV2ControlLightCircuitList: 'Controls/lightV2Control/contentOld/views/lightCircuitList',
    LightV2ControlMoodList: 'Controls/lightV2Control/contentOld/views/moodList',
    LightV2ControlEditMoodList: 'Controls/lightV2Control/contentOld/views/editMoodList',
    LightV2ControlEditMoodScreen: 'Controls/lightV2Control/contentOld/screens/editScreen/editMoodScreen',
    LightV2ControlEditMoodsScreen: 'Controls/lightV2Control/contentOld/screens/editMoodsScreen/editMoodsScreen',
    LightV2ControlNewMoodScreen: 'Controls/lightV2Control/contentOld/screens/newScreen/newMoodScreen',
    LightV2ControlSaveMoodScreen: 'Controls/lightV2Control/contentOld/screens/saveScreen/saveMoodScreen',
    LightV2ControlReplaceMoodScreen: 'Controls/lightV2Control/contentOld/screens/replaceMoodScreen/replaceMoodScreen',
    RenameLightCircuitsScreen: 'Controls/lightV2Control/contentOld/screens/renameLightCircuits/renameLightCircuitsScreen',
    DayLightSettingsScreen: 'Controls/lightV2Control/contentOld/screens/dayLightSettingsScreen/dayLightSettingsScreen',

    // MailBoxControl
    MailBoxControl: 'Controls/mailBoxControl/mailBoxControl',
    MailBoxControlEnums: 'Controls/mailBoxControl/mailBoxControlEnums',
    MailBoxControlCommandSrc: 'Controls/mailBoxControl/mailBoxControlCommandSrc',
    MailBoxControlStatesSrc: 'Controls/mailBoxControl/mailBoxControlStatesSrc',

    // MediaClientControl
    MediaClientControl: 'Controls/mediaClientControl/mediaClientControl',
    MediaClientControlEnums: 'Controls/mediaClientControl/mediaClientControlEnums',
    MediaClientControlCommandSrc: 'Controls/mediaClientControl/mediaClientControlCommandSrc',
    MediaClientControlContent: 'Controls/mediaClientControl/content/controlContent',

    // Meter
    MeterControl: 'Controls/meterControl/meterControl',
    MeterControlStatesSrc: 'Controls/meterControl/meterControlStatesSrc',

    // MeterV2
    MeterV2Control: 'Controls/meterV2Control/meterV2Control',
    MeterV2ControlContent: 'Controls/meterV2Control/content/controlContent',

    // NfcCodeTouch
    NfcCodeTouchControl: 'Controls/nfcCodeTouchControl/nfcCodeTouchControl',
    NfcCodeTouchControlEnums: 'Controls/nfcCodeTouchControl/nfcCodeTouchControlEnums',
    NfcCodeTouchControlCommandSrc: 'Controls/nfcCodeTouchControl/nfcCodeTouchControlCommandSrc',
    NfcCodeTouchControlStatesSrc: 'Controls/nfcCodeTouchControl/nfcCodeTouchControlStatesSrc',
    NfcCodeTouchControlContent: 'Controls/nfcCodeTouchControl/content/controlContent',
    NfcCodeTouchControlContentHistory: 'Controls/nfcCodeTouchControl/content/history/history',
    NfcCodeTouchControlContentCodes: 'Controls/nfcCodeTouchControl/content/codes/codes',
    NfcCodeTouchControlContentCode: 'Controls/nfcCodeTouchControl/content/code/code',
    NfcCodeTouchControlContentCodeValidity: 'Controls/nfcCodeTouchControl/content/codeValidity/codeValidity',
    NfcCodeTouchControlContentCodePermissions: 'Controls/nfcCodeTouchControl/content/codePermissions/codePermissions',
    NfcCodeTouchControlContentLearnNfcTag: 'Controls/nfcCodeTouchControl/content/nfcLearnTag/nfcLearnTag',

    // RadioControl
    RadioControl: 'Controls/radioControl/radioControl',
    RadioControlCommandSrc: 'Controls/radioControl/radioControlCommandSrc',

    // RemoteControl
    RemoteControl: 'Controls/remoteControl/remoteControl',
    RemoteControlEnums: 'Controls/remoteControl/remoteControlEnums',
    RemoteControlCommandSrc: 'Controls/remoteControl/remoteControlCommandSrc',
    RemoteControlContent: 'Controls/remoteControl/content/controlContent',
    RemoteKeyboard: 'Controls/remoteControl/content/keyboard/remoteKeyboard',

    // Pushbutton
    PushbuttonControl: 'Controls/pushbuttonControl/pushbuttonControl',
    PushbuttonControlCommandSrc: 'Controls/pushbuttonControl/pushbuttonControlCommandSrc',

    // LoadManager
    LoadManagerControl: 'Controls/loadManagerControl/loadManagerControl',
    LoadManagerControlStatesSrc: 'Controls/loadManagerControl/loadManagerControlStatesSrc',
    LoadManagerControlContent: 'Controls/loadManagerControl/content/controlContent',
    LoadManagerProgressView: 'Controls/loadManagerControl/content/loadManagerProgressView/loadManagerProgressView',

    // PulseAt
    PulseAtControl: 'Controls/pulseAtControl/pulseAtControl',
    PulseAtControlEnums: 'Controls/pulseAtControl/pulseAtControlEnums',
    PulseAtControlCommandSrc: 'Controls/pulseAtControl/pulseAtControlCommandSrc',
    PulseAtControlStatesSrc: 'Controls/pulseAtControl/pulseAtControlStatesSrc',
    // EnergyFlowMonitor
    EnergyFlowMonitorControl: 'Controls/energyFlowMonitorControl/energyFlowMonitorControl',
    EnergyFlowMonitorControlContent: 'Controls/energyFlowMonitorControl/content/controlContent',

    // EnergyManager
    EnergyManagerControl: 'Controls/energyManagerControl/energyManagerControl',
    EnergyManagerControlContent: 'Controls/energyManagerControl/content/controlContent',
    EnergyManagerControlStatesSrc: 'Controls/energyManagerControl/energyManagerControlStatesSrc',

    // EnergyManagerV2
    EnergyManager2Control: 'Controls/energyManager2Control/energyManager2Control',
    EnergyManager2ControlContent: 'Controls/energyManager2Control/content/controlContent',

    // AcController
    AcControl: "Controls/acControl/acControl",
    AcControlContent: 'Controls/acControl/content/controlContent',
    AcControlCommandSrc: 'Controls/acControl/acControlCommandSrc',
    AcControlEnums: 'Controls/acControl/acControlEnums',

    // Spot prize Optimizer
    SpotPriceOptimizerControl: 'Controls/spotPriceOptimizerControl/spotPriceOptimizerControl',
    SpotPriceOptimizerControlContent: 'Controls/spotPriceOptimizerControl/content/controlContent',
    SpotPriceOptimizerControlEnums: 'Controls/spotPriceOptimizerControl/spotPriceOptimizerControlEnums',

    // SaunaControl
    SaunaControl: 'Controls/saunaControl/saunaControl',
    SaunaControlEnums: 'Controls/saunaControl/saunaControlEnums',
    SaunaControlCommandSrc: 'Controls/saunaControl/saunaControlCommandSrc',
    SaunaControlStatesSrc: 'Controls/saunaControl/saunaControlStatesSrc',
    SaunaControlContent: 'Controls/saunaControl/content/controlContent',
    SaunaControlInfoView: 'Controls/saunaControl/content/saunaControlInfoView',
    TargetValueSelectorScreenBase: 'Controls/saunaControl/content/targetValues/targetValueSelectorScreenBase',
    TargetTempSelectorScreen: 'Controls/saunaControl/content/targetValues/targetTempSelectorScreen',
    TargetHumiditySelectorScreen: 'Controls/saunaControl/content/targetValues/targetHumiditySelectorScreen',

    //SequentialControl
    SequentialControl: 'Controls/sequentialControl/sequentialControl',
    SequentialControlCommandSrc: 'Controls/sequentialControl/sequentialControlCommandSrc',

    // Slider
    SliderControl: 'Controls/sliderControl/sliderControl',
    SliderControlContent: 'Controls/sliderControl/content/controlContent',

    // Switch
    SwitchControl: 'Controls/switchControl/switchControl',
    SwitchControlCommandSrc: 'Controls/switchControl/switchControlCommandSrc',

    // SystemScheme
    SystemSchemeControl: 'Controls/systemSchemeControl/systemSchemeControl',
    SystemSchemeControlContent: 'Controls/systemSchemeControl/content/controlContent',

    // TextState
    TextStateControl: 'Controls/textStateControl/textStateControl',

    // TextInput
    TextInputControl: 'Controls/textInputControl/textInputControl',
    TextInputControlEnums: 'Controls/textInputControl/textInputControlEnums',
    TextInputControlContent: 'Controls/textInputControl/content/controlContent',
    TextInputControlEdit: 'Controls/textInputControl/content/views/editText',

    // TimedSwitch
    TimedSwitchControl: 'Controls/timedSwitchControl/timedSwitchControl',
    TimedSwitchControlCommandSrc: 'Controls/timedSwitchControl/timedSwitchControlCommandSrc',

    // TrackerControl
    TrackerControl: 'Controls/trackerControl/trackerControl',
    TrackerControlContent: 'Controls/trackerControl/content/controlContent',
    TrackerView: 'Controls/trackerControl/trackerView/trackerView',
    TrackerControlInfoView: 'Controls/trackerControl/content/infoView/trackerControlInfoView',

    // UpDownLeftRightAnalog
    UpDownLeftRightAnalogControl: 'Controls/upDownLeftRightControl/upDownLeftRightAnalogControl',
    UpDownLeftRightAnalogControlCommandSrc: 'Controls/upDownLeftRightControl/upDownLeftRightAnalogControlCommandSrc',

    // UpDownLeftRightDigital
    UpDownLeftRightDigitalControl: 'Controls/upDownLeftRightControl/upDownLeftRightDigitalControl',
    UpDownLeftRightDigitalControlCommandSrc: 'Controls/upDownLeftRightControl/upDownLeftRightDigitalControlCommandSrc',


    // PresenceDetection
    PresenceDetectorControl: 'Controls/presenceDetectorControl/presenceDetectorControl',
    PresenceDetectorControlStatesSrc: 'Controls/presenceDetectorControl/presenceDetectorControlStatesSrc',


    // ValueSelector
    ValueSelectorControl: 'Controls/valueSelectorControl/valueSelectorControl',
    ValueSelectorControlCommandSrc: 'Controls/valueSelectorControl/valueSelectorControlCommandSrc',

    // JalousieControl
    JalousieControl: 'Controls/jalousieControl/jalousieControl',
    JalousieControlEnums: 'Controls/jalousieControl/jalousieControlEnums',
    JalousieControlCommandSrc: 'Controls/jalousieControl/jalousieControlCommandSrc',
    JalousieControlStatesSrc: 'Controls/jalousieControl/jalousieControlStatesSrc',
    JalousieControlContent: 'Controls/jalousieControl/content/controlContent',
    JalousieCentralControl: 'Controls/jalousieCentralControl/jalousieCentralControl',
    JalousieCentralControlCommandSrc: 'Controls/jalousieCentralControl/jalousieCentralControlCommandSrc',

    // Jalousie Configuration (Geiger)
    JalousieControlContentConfigAdjustScreen: 'Controls/jalousieControl/content/configDialog/adjust/jalousieConfigAdjustScreen',
    JalousieControlContentConfigHelpScreen: 'Controls/jalousieControl/content/configDialog/help/jalousieConfigHelpScreen',
    JalousieControlContentConfigInfoScreen: 'Controls/jalousieControl/content/configDialog/info/jalousieConfigInfoScreen',
    JalousieControlContentConfigSettingsScreen: 'Controls/jalousieControl/content/configDialog/settings/jalousieConfigSettingsScreen',
    JalousieControlContentConfigWaitingScreen: 'Controls/jalousieControl/content/configDialog/waiting/jalousieConfigWaitingScreen',
    JalousieControlContentConfigDialog: 'Controls/jalousieControl/content/configDialog/jalousieControlConfigDialog',

    // Adjusting the end positions of a jalousie
    JalousieEndPositionAdjustmentScreen: 'Controls/jalousieControl/content/endPosAdjustment/endPosAdjustmentScreen',

    // Irrigation
    IrrigationControl: 'Controls/irrigationControl/irrigationControl',
    IrrigationControlCommandSrc: 'Controls/irrigationControl/irrigationControlCommandSrc',
    IrrigationControlStatesSrc: 'Controls/irrigationControl/irrigationControlStatesSrc',
    IrrigationControlEnums: 'Controls/irrigationControl/irrigationControlEnums',
    IrrigationDurationSettings: 'Controls/irrigationControl/content/durationSettings/durationSettingsScreen',

    // Intercom
    IntercomControl: 'Controls/intercomControl/intercomControl',
    IntercomControlCommandSrc: 'Controls/intercomControl/intercomControlCommandSrc',
    IntercomControlStatesSrc: 'Controls/intercomControl/intercomControlStatesSrc',
    IntercomControlEnums: 'Controls/intercomControl/intercomControlEnums',
    IntercomControlContent: 'Controls/intercomControl/content/controlContent',
    IntercomControlInfoView: 'Controls/intercomControl/content/infoView/intercomControlInfoView',
    IntercomVideoView: 'Controls/intercomControl/content/views/videoView/intercomVideoView',
    IntercomControlContentMissedView: 'Controls/intercomControl/content/missedList/missedView/intercomControlContentMissedView',
    IntercomControlContentMissedList: 'Controls/intercomControl/content/missedList/intercomControlContentMissedList',
    IntercomControlContentSettings: 'Controls/intercomControl/content/settings/intercomControlContentSettings',
    IntercomControlAboutIntercomScreen: 'Controls/intercomControl/content/settings/about/aboutIntercomScreen',
    IntercomVideoProvider: 'Controls/intercomControl/content/intercomVideoProvider',

    // PoolControl
    PoolControl: 'Controls/poolControl/poolControl',
    PoolControlCommandSrc: 'Controls/poolControl/poolControlCommandSrc',
    PoolControlStatesSrc: 'Controls/poolControl/poolControlStatesSrc',
    PoolControlEnums: 'Controls/poolControl/poolControlEnums',

    PoolControlContent: 'Controls/poolControl/content/controlContent',
    AnalogSwimmingMachineScreen: 'Controls/poolControl/content/swimmingMachine/analogSwimmingMachineScreen',
    PoolCoverScreen: 'Controls/poolControl/content/poolCoverScreen/poolCoverScreen',
    ServiceModeScreen: 'Controls/poolControl/content/serviceModeScreen/serviceModeScreen',

    PoolControlContentOperatingModeList: 'Controls/poolControl/content/operatingModeList/operatingModeList',
    PoolControlContentCycleSelection: 'Controls/poolControl/content/poolDaytimerControl/poolDaytimerCycleModeList',
    PoolDaytimerControl: 'Controls/poolControl/content/poolDaytimerControl/poolDaytimerControl',
    PoolDaytimerCalendarEntries: 'Controls/poolControl/content/poolDaytimerControl/calendarEntries',
    PoolDaytimerControlContentCalendar: 'Controls/poolControl/content/poolDaytimerControl/calendarView',
    ServiceModeStateView: 'Controls/poolControl/content/serviceModeStateView/serviceModeStateView',
    PoolControlContentSettings: 'Controls/poolControl/content/settingsScreen/settingsScreen',
    PoolControlContentTemperatureModeList: 'Controls/poolControl/content/temperatureMode/temperatureModeList',
    PoolControlContentTimer: 'Controls/poolControl/content/timerView/timerView',
    PoolControlContentValvePositionScreen: 'Controls/poolControl/content/valvePosition/valvePositionScreen',

    // Daytimer
    DaytimerControlBase: 'Controls/daytimerControl/daytimerControlBase',
    DaytimerControl: 'Controls/daytimerControl/daytimerControl',
    DaytimerControlEnums: 'Controls/daytimerControl/daytimerControlEnums',
    DaytimerControlCommandSrc: 'Controls/daytimerControl/daytimerControlCommandSrc',
    DaytimerControlStatesSrc: 'Controls/daytimerControl/daytimerControlStatesSrc',
    DaytimerControlContent: 'Controls/daytimerControl/content/controlContent',
    DaytimerControlInfoView: 'Controls/daytimerControl/content/daytimerControlInfoView',

    DaytimerControlContentCalendar: 'Controls/daytimerControl/content/calendarView/calendarView',
    DaytimerControlContentEntryDetail: 'Controls/daytimerControl/content/entryDetail/entryDetail',
    DaytimerControlContentOperatingModes: 'Controls/daytimerControl/content/operatingModeList/operatingModeList',
    DaytimerControlContentDefaultValue: 'Controls/daytimerControl/content/defaultValue/defaultValue',
    DaytimerControlContentTimer: 'Controls/daytimerControl/content/timerView/timerView',
    DaytimerCalendarEntries: 'Controls/daytimerControl/content/views/calendarEntries',
    DaytimerListEntries: 'Controls/daytimerControl/content/views/listViewEntries',
    DaytimerTimeLine: 'Controls/daytimerControl/content/views/timeLine', // view
    DaytimerOperationModeBar: 'Controls/daytimerControl/content/HD/operationModeBar/operationModeBar', // view
    DaytimerSplitOperationModeView: 'Controls/daytimerControl/content/HD/splitOperationModeView/splitOperationModeView', // view
    DaytimerControlContentCalendarHD: 'Controls/daytimerControl/content/HD/calendarViewHD',


    // RoomController
    IRoomControl: 'Controls/iRoomControl/iRoomControl',
    IRoomControlCommandSrc: 'Controls/iRoomControl/iRoomControlCommandSrc',
    IRoomControlEnums: 'Controls/iRoomControl/iRoomControlEnums',
    IRoomControlContent: 'Controls/iRoomControl/content/controlContent',
    IRoomControlInfoView: 'Controls/iRoomControl/content/iRoomControlInfoView',
    IRCManualTempSliderScreen: 'Controls/iRoomControl/content/ircManualTempSliderScreen',

    ServiceModeList: 'Controls/iRoomControl/content/views/serviceModeList',
    TimeLine: 'Controls/iRoomControl/content/views/timeLine',
    ManualModeViewV2: 'Controls/iRoomControl/content/views/manualModeViewV2',
    IRoomControlContentTimer: 'Controls/iRoomControl/content/timerView/timerView',
    IRoomControlContentSettingsList: 'Controls/iRoomControl/content/settingsList/settingsList',
    IRoomControlContentControllerMode: 'Controls/iRoomControl/content/operatingModeList/controllerModeList',
    IRoomControlContentPeriod: 'Controls/iRoomControl/content/periodView/periodView',
    IRoomControlContentTemperatureList: 'Controls/iRoomControl/content/temperatureModeList/temperatureModeList',
    TempListValueEditor: 'Controls/iRoomControl/content/views/tempListValueEditor',
    IRCDaytimerControl: 'Controls/iRoomControl/content/ircDaytimerControl/ircDaytimerControl',
    IRCDaytimerControlContentCalendar: 'Controls/iRoomControl/content/ircDaytimerControl/calendarView',
    IRoomControlContentTemperatureSelection: 'Controls/iRoomControl/content/ircDaytimerControl/ircDaytimerTempModeList',
    IRCDaytimerCalendarEntries: 'Controls/iRoomControl/content/ircDaytimerControl/calendarEntries',

    // RoomV2Controller
    IRoomControllerV2Control: 'Controls/iRoomControllerV2/iRoomV2Control',
    IRoomControllerV2ControlCommandSrc: 'Controls/iRoomControllerV2/iRoomV2ControlCommandSrc',
    IRoomControllerV2ControlEnums: 'Controls/iRoomControllerV2/iRoomV2ControlEnums',
    IRoomControllerV2ControlContent: 'Controls/iRoomControllerV2/content/controlContent',
    IRoomControllerV2ControlOverride: 'Controls/iRoomControllerV2/content/override/overrideScreen',
    IRoomControllerV2SettingsScreen: "Controls/iRoomControllerV2/content/settings/settingsScreen",
    IRoomControllerV2TemperaturesScreen: "Controls/iRoomControllerV2/content/settings/temperatures/temperatures",
    IRoomControllerV2OperatingModesScreen: "Controls/iRoomControllerV2/content/settings/operatingModes/operatingModesScreen",
    IRCV2DaytimerControl: 'Controls/iRoomControllerV2/content/ircv2DaytimerControl/ircv2DaytimerControl',
    IRCV2DaytimerControlContentCalendar: 'Controls/iRoomControllerV2/content/ircv2DaytimerControl/calendarView',
    IRoomV2ControlContentTemperatureSelection: 'Controls/iRoomControllerV2/content/ircv2DaytimerControl/ircv2DaytimerTempModeList',
    IRCV2DaytimerCalendarEntries: 'Controls/iRoomControllerV2/content/ircv2DaytimerControl/calendarEntries',
    IRCV2AvgTempLimitsScreen: 'Controls/iRoomControllerV2/content/settings/avgTempLimits/avgTempLimitsScreen',

    // IRoomV2021Controller
    IRoomControllerV2021Control: 'Controls/iRoomControllerV2021/iRoomV2021Control',
    IRoomControllerV2021ControlCommandSrc: 'Controls/iRoomControllerV2021/iRoomV2021ControlCommandSrc',
    IRoomControllerV2021ControlEnums: 'Controls/iRoomControllerV2021/iRoomV2021ControlEnums',
    IRoomControllerV2021ControlContent: 'Controls/iRoomControllerV2021/content/controlContent',
    IRCTempSettingsScreen: "Controls/iRoomControllerV2021/content/tempSettingsScreen/tempSettingsScreen",
    Irc2021TempAdoptionScreen: 'Controls/iRoomControllerV2021/content/tempAdoptionScreen/tempAdoptionScreen',
    IrcFixedSetpointUtil: 'Controls/iRoomControllerV2021/IrcFixedSetpointUtil',

    Irc2021StateOverview: 'Controls/iRoomControllerV2021/content/stateOverview/stateOverview',
    IrcTempSliders: 'Controls/iRoomControllerV2021/content/ircTempSliders/ircTempSliders',

    //MsShortcut
    MsShortcutControl: 'Controls/msShortcutControl/msShortcutControl',
    MsShortcutControlCommandSrc: 'Controls/msShortcutControl/msShortcutControlCommandSrc',

    // Wallbox2
    Wallbox2Control: 'Controls/wallbox2Control/wallbox2Control',
    Wallbox2ControlContent: 'Controls/wallbox2Control/content/controlContent',

    // WallboxManager
    WallboxManagerControl: 'Controls/wallboxManagerControl/wallboxManagerControl',
    WallboxManagerControlStatesSrc: 'Controls/wallboxManagerControl/wallboxManagerControlStatesSrc',

    // Weather
    WeatherControl: 'Controls/weatherControl/weatherControl',

    // WindowMonitor
    WindowMonitorControl: 'Controls/windowMonitorControl/windowMonitorControl',
    WindowMonitorControlStatesSrc: 'Controls/windowMonitorControl/windowMonitorControlStatesSrc',
    WindowMonitorControlEnums: 'Controls/windowMonitorControl/windowMonitorControlEnums',

    // Status Monitor
    StatusMonitorControl: 'Controls/statusMonitorControl/statusMonitorControl',
    StatusMonitorControlStatesSrc: 'Controls/statusMonitorControl/statusMonitorControlStatesSrc',

    // Window Control
    WindowControl: 'Controls/windowControl/windowControl',
    WindowControlCommandSrc: 'Controls/windowControl/windowControlCommandSrc',
    WindowControlEnums: 'Controls/windowControl/windowControlEnums',

    // Window Central Control
    CentralWindowControl: 'Controls/windowCentralControl/windowCentralControl',
    CentralWindowControlCommandSrc: 'Controls/windowCentralControl/windowCentralControlCommandSrc',

    //Steak
    SteakControl: 'Controls/steakControl/steakControl',
    SteakControlEnums: 'Controls/steakControl/steakControlEnums',
    SteakControlContent: 'Controls/steakControl/content/controlContent',
    SteakControlCommandSrc: 'Controls/steakControl/steakControlCommandSrc',
    SteakControlStatesSrc: 'Controls/steakControl/steakControlStatesSrc',
    SteakTargetTempSelectorScreen: 'Controls/steakControl/content/valueSelector/targetTempSelectorScreen',
    SteakTimeSelectorScreen: 'Controls/steakControl/content/valueSelector/timeSelectorScreen',
    SteakRenameScreen: 'Controls/steakControl/content/valueSelector/renameScreen',
    SteakControlContentSettings: 'Controls/steakControl/content/settings/settingsScreen',

    //SolarPump
    SolarPumpControl: 'Controls/solarPumpControl/solarPumpControl',
    SolarPumpControlEnums: 'Controls/solarPumpControl/solarPumpControlEnums',
    SolarPumpControlStatesSrc: 'Controls/solarPumpControl/solarPumpControlStatesSrc',

    // PowerControl
    PowerUnitControl: 'Controls/powerUnitControl/powerUnitControl',
    PowerUnitControlEnums: 'Controls/powerUnitControl/powerUnitControlEnums',
    PowerUnitControlStatesSrc: 'Controls/powerUnitControl/powerUnitControlStatesSrc',

    //Ventilation
    VentilationControl: 'Controls/ventilationControl/ventilationControl',
    VentilationControlCommandSrc: 'Controls/ventilationControl/ventilationControlCommandSrc',
    VentilationControlStatesSrc: 'Controls/ventilationControl/ventilationControlStatesSrc',
    VentilationControlEnums: 'Controls/ventilationControl/ventilationControlEnums',
    VentilationControlVentTimerScreen: 'Controls/ventilationControl/content/timerScreen/timerScreen',
    VentilationControlModesScreen: 'Controls/ventilationControl/content/modesScreen/modesScreen',
    VentilationControlSettingsScreen: 'Controls/ventilationControl/content/settings/settingsScreen',

    // Autopilot
    AutopilotScreen: 'GUI/ActiveMiniserver/autopilot/screens/autopilotScreen',
    AutopilotHistoryScreen: 'GUI/ActiveMiniserver/autopilot/screens/history/autopilotHistoryScreen',
    AutopilotRulesScreen: 'GUI/ActiveMiniserver/autopilot/screens/rules/autopilotRulesScreen',
    AutopilotRuleScreen: 'GUI/ActiveMiniserver/autopilot/screens/rule/autopilotRuleScreen',

    AutopilotEventScreen: 'GUI/ActiveMiniserver/autopilot/screens/events/autopilotEventScreen',
    AutopilotEventDetailsControlScreen: 'GUI/ActiveMiniserver/autopilot/screens/events/autopilotEventDetailsControlScreen',
    AutopilotEventDetailsSimpleScreen: 'GUI/ActiveMiniserver/autopilot/screens/events/autopilotEventDetailsSimpleScreen',

    AutopilotCategoryScreen: 'GUI/ActiveMiniserver/autopilot/screens/category/autopilotCategoryScreen',

    AutopilotActionsScreen: 'GUI/ActiveMiniserver/autopilot/screens/actions/autopilotActionsScreen',
    AutopilotOperatingModeScreen: 'GUI/ActiveMiniserver/autopilot/screens/actions/operatingMode/autopilotOperatingModeScreen',
    AutopilotNotificationScreen: 'GUI/ActiveMiniserver/autopilot/screens/actions/notification/autopilotNotificationScreen',
    AutopilotControlCommandsScreen: 'GUI/ActiveMiniserver/autopilot/screens/actions/controlCommands/autopilotControlCommandsScreen',

    AutopilotCommunicator: 'GUI/ActiveMiniserver/autopilot/communication/autopilotCommunicator',
    AutopilotUtility: 'GUI/ActiveMiniserver/autopilot/utility/autopilotUtility',
    AutopilotEnums: 'GUI/ActiveMiniserver/autopilot/utility/autopilotEnums',

    ControlHelpScreen: 'GUI/ActiveMiniserver/controlHelp/controlHelpScreen',
    ControlLockingScreen: 'GUI/ActiveMiniserver/controlLocking/controlLockingScreen',

    ControlSetting: 'GUI/ActiveMiniserver/controlSettings/controlSetting',
    ControlSettingsController: 'GUI/ActiveMiniserver/controlSettings/controlSettingsController',
    ControlSettingsScreen: 'GUI/ActiveMiniserver/controlSettings/controlSettingsScreen',
    ControlSettingsEditScreen: 'GUI/ActiveMiniserver/controlSettings/controlSettingsEditScreen',
    ControlSettingsEditIconScreen: 'GUI/ActiveMiniserver/controlSettings/controlSettingsEditIconScreen',
    ControlPerDeviceFavoritesController: 'GUI/ActiveMiniserver/controlSettings/controlPerDeviceFavoritesController',
    ControlPresetSetting: 'GUI/ActiveMiniserver/controlSettings/controlPresetSetting',
    ControlSettingsUtil: 'GUI/ActiveMiniserver/controlSettings/controlSettingsUtil',

    // Automatic Designer
    AutomaticDesignerFilterView: 'GUI/ActiveMiniserver/automaticDesigner/views/filterView/filterView',
    AutomaticDesignerOverviewScreen: 'GUI/ActiveMiniserver/automaticDesigner/screens/overviewScreen/overviewScreen',
    AutomaticDesignerScenesScreen: 'GUI/ActiveMiniserver/automaticDesigner/screens/overviewScreen/overviewScenesScreen',
    AutomaticDesignerRulesScreen: 'GUI/ActiveMiniserver/automaticDesigner/screens/overviewScreen/rulesScreen/rulesScreen',
    AutomaticDesignerHistoryScreen: 'GUI/ActiveMiniserver/automaticDesigner/screens/overviewScreen/historyScreen/historyScreen',
    AutomaticDesignerRuleViewController: 'GUI/ActiveMiniserver/automaticDesigner/screens/rule/ruleViewController',
    AutomaticDesignerRuleMenuViewController: 'GUI/ActiveMiniserver/automaticDesigner/screens/rule/ruleScreens/ruleMenu/ruleMenuViewController',
    AutomaticDesignerAddOrEditRule: 'GUI/ActiveMiniserver/automaticDesigner/screens/addOrEditRuleScreen/addOrEditRuleScreen',
    AutomaticDesignerAddOrEditScene: 'GUI/ActiveMiniserver/automaticDesigner/screens/addOrEditSceneScreen/addOrEditSceneScreen',
    AutomaticDesignerSceneVisuEditScreen: 'GUI/ActiveMiniserver/automaticDesigner/screens/addOrEditSceneScreen/visualization/visuEditScreen',
    AutomaticDesignerRuleMenuScreen: 'GUI/ActiveMiniserver/automaticDesigner/screens/rule/ruleScreens/ruleMenu/ruleMenuScreen',
    AutomaticDesignerSearchViewController: 'GUI/ActiveMiniserver/automaticDesigner/screens/rule/ruleScreens/ruleMenu/searchViewController',
    AutomaticDesignerSearchScreen: 'GUI/ActiveMiniserver/automaticDesigner/screens/rule/ruleScreens/ruleMenu/searchScreen',
    AutomaticDesignerRuleMenuPageScreen: 'GUI/ActiveMiniserver/automaticDesigner/screens/rule/ruleScreens/ruleMenu/ruleMenuPageScreen',

    AutomaticDesignerEnums: 'AutomaticDesignerComp/automaticDesignerEnums',
    AutomaticDesignerTableContentHelper: 'GUI/ActiveMiniserver/automaticDesigner/helper/tableContentHelper',

    // Paired App
    PairedAppEnums: 'PairedAppComp/pairedAppEnums',
    PairSetupCommunicator: 'PairedAppComp/helper/PairSetupCommunicator',

    // Statistic
    StatisticScreen: 'GUI/ActiveMiniserver/statistic/statisticScreen',
    StatisticHeader: 'GUI/ActiveMiniserver/statistic/statisticHeader/statisticHeader',
    ChartView: 'GUI/ActiveMiniserver/statistic/chartView',
    ScrollView: 'GUI/ActiveMiniserver/statistic/scrollView',
    IndicatorView: 'GUI/ActiveMiniserver/statistic/indicatorView/indicatorView',
    BarChartView: 'GUI/ActiveMiniserver/statistic/charts/barChartView',
    DigitalChartView: 'GUI/ActiveMiniserver/statistic/charts/digitalChartView',
    LineChartView: 'GUI/ActiveMiniserver/statistic/charts/lineChartView',
    StepChartView: 'GUI/ActiveMiniserver/statistic/charts/stepChartView',

    // MENU
    MenuScreen: 'GUI/ActiveMiniserver/menu/menuScreen',
    NewsHubScreen: 'GUI/ActiveMiniserver/newsHub/newsHubScreen',
    MessageCenterMessagesScreen: 'GUI/ActiveMiniserver/messageCenter/messagesScreen',
    MessageCenterMessageScreen: 'GUI/ActiveMiniserver/messageCenter/messageScreen',
    AboutScreenBase: 'GUI/ActiveMiniserver/menu/aboutScreenBase/aboutScreenBase',
    BurgerMenuScreen: 'GUI/ActiveMiniserver/menu/burgerMenu/burgerMenuScreen',
    MiniserverDetailsScreen: 'GUI/ActiveMiniserver/menu/miniserverDetails/miniserverDetailsScreen',
    HelpFeedbackScreen: 'GUI/ActiveMiniserver/menu/helpFeedback/helpFeedbackScreen',
    AboutDeviceScreen: 'GUI/ActiveMiniserver/menu/aboutDeviceScreen/aboutDeviceScreen',
    TableViewDebugScreen: 'GUI/ActiveMiniserver/menu/tableViewDebug/tableViewDebugScreen',
    TableViewShowCase: 'GUI/ActiveMiniserver/menu/tableViewShowCase/tableViewShowCaseScreen',
    IconLibShowcase: 'GUI/ActiveMiniserver/menu/iconLibShowcase/iconLibShowcaseScreen',

    MiniserverSelectionScreen: 'GUI/ActiveMiniserver/miniserverSelection/miniserverSelectionScreen',

    // Miniserver Settings
    MiniserverSettingsScreen: 'GUI/ActiveMiniserver/menu/miniserverSettings/miniserverSettingsScreen',
    ArrangementSettingsScreen: 'GUI/ActiveMiniserver/menu/miniserverSettings/arrangementSettings/arrangementSettingsScreen',
    ChangeSecretScreen: 'GUI/ActiveMiniserver/menu/miniserverSettings/changeSecret/changeSecretScreen',
    //ExpertModeSettingsScreen: 'GUI/ActiveMiniserver/menu/miniserverSettings/expertModeSettings/expertModeSettingsScreen',
    BackupAndSyncWelcomeScreen: 'GUI/ActiveMiniserver/menu/backupAndSync/backupAndSyncWelcome/backupAndSyncWelcomeScreen',
    BackupAndSyncSettingsScreen: 'GUI/ActiveMiniserver/menu/backupAndSync/backupAndSyncSettings/backupAndSyncSettingsScreen',
    BackupAndSyncRestoreScreen: 'GUI/ActiveMiniserver/menu/backupAndSync/backupAndSyncRestore/backupAndSyncRestoreScreen',
    BackupAndSyncBackupScreen: 'GUI/ActiveMiniserver/menu/backupAndSync/backupAndSyncBackup/backupAndSyncBackupScreen',
    BackupAndSyncRestoredScreen: 'GUI/ActiveMiniserver/menu/backupAndSync/backupAndSyncRestored/backupAndSyncRestoredScreen',
    HomeKitOnboardingScreen: 'GUI/ActiveMiniserver/menu/homeKit/homeKitOnboarding/homeKitOnboardingScreen',
    HomeKitWelcomeScreen: 'GUI/ActiveMiniserver/menu/homeKit/homeKitWelcome/homeKitWelcomeScreen',
    HomeKitUsersScreen: 'GUI/ActiveMiniserver/menu/homeKit/homeKitUsers/homeKitUsersScreen',
    HomeKitSetupCodeScreen: 'GUI/ActiveMiniserver/menu/homeKit/homeKitSetupCode/homeKitSetupCodeScreen',
    HomeKitSetupDoneScreen: 'GUI/ActiveMiniserver/menu/homeKit/homeKitSetupDone/homeKitSetupDoneScreen',
    HomeKitSettingsScreen: 'GUI/ActiveMiniserver/menu/homeKit/homeKitSettings/homeKitSettingsScreen',
    HomeKitSelectHomeScreen: 'GUI/ActiveMiniserver/menu/homeKit/homeKitSelectHome/homeKitSelectHomeScreen',
    HomeKitAccessoriesScreen: 'GUI/ActiveMiniserver/menu/homeKit/homeKitAccessories/homeKitAccessoriesScreen',
    AlexaOnboardingScreen: 'GUI/ActiveMiniserver/menu/amazonAlexa/alexaOnboarding/alexaOnboardingScreen',
    AlexaSetupScreen: 'GUI/ActiveMiniserver/menu/amazonAlexa/alexaSetupScreen/alexaSetupScreen',
    AlexaFinishScreen: 'GUI/ActiveMiniserver/menu/amazonAlexa/alexaFinishScreen/alexaFinishScreen',
    AlexaConfiguredScreen: 'GUI/ActiveMiniserver/menu/amazonAlexa/alexaConfiguredScreen',
    AlexaSelectUserScreen: 'GUI/ActiveMiniserver/menu/amazonAlexa/alexaSelectUserViewController/alexaUserSelectScreen/alexaSelectUserScreen',
    AlexaControlSelectorScreen: 'GUI/ActiveMiniserver/menu/amazonAlexa/alexaControlSelectorScreen/alexaControlSelectorScreen',
    AlexaSetupViewController: 'GUI/ActiveMiniserver/menu/amazonAlexa/alexaSetupViewController',
    AlexaSelectUserViewController: 'GUI/ActiveMiniserver/menu/amazonAlexa/alexaSelectUserViewController/alexaSelectUserViewController',
    WaitingScreen: 'GUI/ActiveMiniserver/screens/waitingScreen/waitingScreen',
    NotificationsSettingsScreen: 'GUI/ActiveMiniserver/menu/miniserverSettings/notificationsSettings/notificationsSettingsScreen',
    NotificationsSettingsBaseScreen: 'GUI/ActiveMiniserver/menu/miniserverSettings/notificationsSettings/notificationsSettingsBaseScreen',
    InAppNotificationsSettingsScreen: 'GUI/ActiveMiniserver/menu/miniserverSettings/notificationsSettings/inAppNotificationsSettingsScreen',
    PushNotificationsSettingsScreen: 'GUI/ActiveMiniserver/menu/miniserverSettings/notificationsSettings/pushNotificationsSettingsScreen',
    // Favorites Settings
    FavoritesSettingsScreen: 'GUI/ActiveMiniserver/menu/miniserverSettings/favoritesSettings/favoritesSettingsScreen',
    GroupSelectionScreen: 'GUI/ActiveMiniserver/menu/miniserverSettings/favoritesSettings/groupSelection/groupSelectionScreen',
    ControlSelectionScreen: 'GUI/ActiveMiniserver/menu/miniserverSettings/favoritesSettings/controlSelection/controlSelectionScreen',
    // Device Favorites
    DeviceFavoritesSettingsScreen: 'GUI/ActiveMiniserver/menu/miniserverSettings/deviceFavoritesSettings/deviceFavoritesSettingsScreen',
    // Current User
    //CurrentUserScreen: 'GUI/ActiveMiniserver/menu/miniserverSettings/currentUser/currentUserScreen',
    // User Management
    //UserManagementScreen: 'GUI/ActiveMiniserver/menu/miniserverSettings/userManagement/userManagementScreen',
    //UserDetailsScreen: 'GUI/ActiveMiniserver/menu/miniserverSettings/userManagement/userDetails/userDetailsScreen',
    LearnNfcTagScreen: 'GUI/ActiveMiniserver/menu/miniserverSettings/userManagement/userDetails/nfcLearnTag/nfcLearnTagScreen',
    NfcSelectorScreen: 'GUI/ActiveMiniserver/menu/miniserverSettings/userManagement/userDetails/nfcLearnTag/selectorScreen/nfcSelectorScreen',
    //NfcEditDialog: 'GUI/ActiveMiniserver/menu/miniserverSettings/userManagement/userDetails/nfcEditDialog/nfcEditDialogScreen',
    //UserGroupsScreen: 'GUI/ActiveMiniserver/menu/miniserverSettings/userManagement/userDetails/userGroupsScreen/userGroupsScreen',
    //TrustPeerUserSelectScreen: 'GUI/ActiveMiniserver/menu/miniserverSettings/userManagement/trustPeerUserSelectScreen/trustPeerUserSelectScreen',
    //Device Search
    DeviceSearchResultScreen: 'GUI/ActiveMiniserver/menu/miniserverSettings/deviceSearchScreen/deviceSearchResultScreen',
    DeviceReplaceScreen: 'GUI/ActiveMiniserver/menu/miniserverSettings/deviceSearchScreen/deviceReplaceScreen/deviceReplaceScreen',
    SelectExtensionScreen: 'GUI/ActiveMiniserver/menu/miniserverSettings/deviceSearchScreen/selectExtensionScreen',
    DeviceSearchViewController: 'GUI/ActiveMiniserver/menu/miniserverSettings/deviceSearchScreen/deviceSearchViewController',
    BackupAndSyncSetupViewController: 'GUI/ActiveMiniserver/menu/backupAndSync/backupAndSyncSetupViewController',
    HomeKitSetupViewController: 'GUI/ActiveMiniserver/menu/homeKit/homeKitSetupViewController',
    DeviceLearningSelectorScreen: 'GUI/ActiveMiniserver/menu/miniserverSettings/deviceSearchScreen/addDeviceScreen/deviceLearningSelector/deviceLearningSelectorScreen',
    DeviceLearningCreateAttributeScreen: 'GUI/ActiveMiniserver/menu/miniserverSettings/deviceSearchScreen/addDeviceScreen/deviceLearningSelector/deviceLearningCreateAttributeScreen/deviceLearningCreateAttributeScreen',
    LearningAssistantAddDeviceScreen: 'GUI/ActiveMiniserver/menu/miniserverSettings/deviceSearchScreen/teachingAssistant/teachingAssistantAddDeviceScreen/teachingAssistantAddDeviceScreen',

    // EntryPoint settings
    EntryPointLocationSelectionScreen: 'GUI/ActiveMiniserver/menu/miniserverSettings/entryPoint/locationSelectionScreen',

    // Tools & Services
    PresenceDetectorScreen: 'GUI/ActiveMiniserver/menu/toolsServices/presenceDetector/presenceDetectorScreen',
    PresenceRoomSelectionScreen: 'GUI/ActiveMiniserver/menu/toolsServices/presenceDetector/presenceRoomSelectionScreen',
    BatteryMonitorScreen: 'GUI/ActiveMiniserver/menu/batteryMonitor/batteryMonitorScreen',
    BatteryDeviceScreen: 'GUI/ActiveMiniserver/menu/batteryMonitor/batteryDevice/batteryDeviceScreen',

    // App Settings
    AppSettingsScreen: 'GUI/ActiveMiniserver/menu/appSettings/appSettingsScreen',
    DarkModeSettingsScreen: 'GUI/ActiveMiniserver/menu/appSettings/darkModeSettings/darkModeSettingsScreen',
    SecuritySettingsScreen: 'GUI/ActiveMiniserver/menu/appSettings/securitySettings/securitySettingsScreen',
    PresentationSettingsScreen: 'GUI/ActiveMiniserver/menu/appSettings/presentationSettingsScreen/presentationSettingsScreen',
    LoxoneControlSettingsScreen: 'GUI/ActiveMiniserver/menu/appSettings/loxoneControlSettings/loxoneControlSettingsScreen',
    LoxoneControlInfoScreen: 'GUI/ActiveMiniserver/menu/appSettings/loxoneControlSettings/loxoneControlInfoScreen',

    // About App/Miniserver
    AboutAppScreen: 'GUI/ActiveMiniserver/menu/aboutApp/aboutAppScreen',
    LicensesScreen: 'GUI/ActiveMiniserver/menu/aboutApp/licensesScreen',
    LicenseDetailScreen: 'GUI/ActiveMiniserver/menu/aboutApp/licenseDetailScreen',
    AboutMiniserverScreen: 'GUI/ActiveMiniserver/menu/aboutMiniserver/aboutMiniserverScreen',

    LoxoneBrandingScreen: 'GUI/ActiveMiniserver/menu/loxoneBranding/loxoneBrandingScreen',
    PartnerBrandingScreen: 'GUI/ActiveMiniserver/menu/partnerBranding/partnerBrandingScreen',

    TableViewTestScreen: 'GUI/ActiveMiniserver/menu/tableViewTest/tableViewTestScreen',

    // Task Recorder V2
    TaskV2DetailsScreen: 'GUI/ActiveMiniserver/taskRecorderV2/taskDetails/taskDetailsScreen',

    // Task Recorder
    TaskRecorderScreen: 'GUI/ActiveMiniserver/taskRecorder/taskRecorderScreen',
    TaskRecordingCommandsScreen: 'GUI/ActiveMiniserver/taskRecorder/taskRecordingCommands/taskRecordingCommandsScreen',
    TaskDetailsScreen: 'GUI/ActiveMiniserver/taskRecorder/taskDetails/taskDetailsScreen',

    QrCodeScreen: 'GUI/ActiveMiniserver/qrCode/qrCodeScreen',
    QrCodeResultScreen: 'GUI/ActiveMiniserver/qrCode/qrCodeResult/qrCodeResultScreen',
    QrCodeView: 'GUI/views/lxQRCodeView/lxQRCodeView',

    NfcScreen: 'GUI/ActiveMiniserver/nfc/nfcScreen',
    NfcTagInteractionScreen: 'GUI/ActiveMiniserver/nfc/nfcTagInteraction/nfcTagInteractionScreen',
    ShareMiniserverScreen: 'GUI/ActiveMiniserver/nfc/shareMiniserver/shareMiniserverScreen',
    NfcInstructionView: 'GUI/ActiveMiniserver/nfc/views/nfcInstructionView/nfcInstructionView',
    NfcTagContentView: 'GUI/ActiveMiniserver/nfc/views/nfcTagContentView/nfcTagContentView',
    NfcLearningDialog: 'GUI/ActiveMiniserver/nfc/views/nfcLearningDialog/nfcLearningDialog',

    OperatingModeScreen: 'GUI/ActiveMiniserver/operatingMode/operatingModeScreen',
    OperatingModeEnums: 'GUI/ActiveMiniserver/operatingMode/operatingModeEnums',

    SearchScreen: 'GUI/ActiveMiniserver/search/searchScreen',
    SearchResultScreen: 'GUI/ActiveMiniserver/search/searchResultScreen',
    OpModeScheduleDetailScreen: 'GUI/ActiveMiniserver/operatingMode/opModeScheduleDetailScreen',

    // CardScreen
    CardScreen: 'GUI/ViewController/cardScreen/cardScreen',

    SortingScreen: 'GUI/ViewController/cardScreen/sortingScreen',


    // DeviceManagement
    InitialSearchScreen: 'GUI/DeviceManagement/initialSearch/initialSearchScreen',
    WelcomeScreen: 'GUI/DeviceManagement/welcome/welcomeScreen',
    ArchiveScreen: 'GUI/DeviceManagement/archive/archiveScreen',
    AppPairingScreen: 'GUI/DeviceManagement/appPairingScreen',
    MiniserverSearchScreen: 'GUI/DeviceManagement/miniserverSearch/miniserverSearchScreen',
    EnterUrlScreen: 'GUI/DeviceManagement/enterUrl/enterUrlScreen',
    CredentialsScreen: 'GUI/DeviceManagement/credentials/credentialsScreen',
    ConnectingWaitingScreen: 'GUI/DeviceManagement/connectingWaiting/connectingWaitingScreen',
    ErrorScreen: 'GUI/DeviceManagement/error/errorScreen',


    // Views
    TimerUntilScreen: 'GUI/views/timerUntilScreen/timerUntilScreen',
    TimerUntilOptionsScreen: 'GUI/views/timerUntilScreen/timerUntilOptionsScreen',
    SubControlView: 'GUI/views/subControlView/subControlView',
    ControlNoteView: 'GUI/views/lxControlNoteView/lxControlNoteView',

    // Screens
    SelectorTimerScreen: 'GUI/ActiveMiniserver/screens/selectorScreen/selectorTimerScreen',

    //AAL Emergency Control
    AalEmergencyControl: 'Controls/aalEmergencyControl/aalEmergencyControl',
    AalEmergencyControlEnums: 'Controls/aalEmergencyControl/aalEmergencyControlEnums',
    AalEmergencyControlCommandSrc: 'Controls/aalEmergencyControl/aalEmergencyControlCommandSrc',
    AalEmergencyControlStatesSrc: 'Controls/aalEmergencyControl/aalEmergencyControlStatesSrc',
    AalEmergencyControlContent: 'Controls/aalEmergencyControl/content/controlContent',

    ControlStateContainer: 'Controls/controlStateContainer',
    ControlCentralStateContainer: 'Controls/controlCentralStateContainer',
    GlobalStateContainer: 'SandboxComp/globalStateContainer',
    MediaStateContainer: 'SandboxComp/mediaStateContainer',
    AutopilotGeneratorStateContainer: 'SandboxComp/autopilotGeneratorStateContainer',
    MessageCenterStateContainer: 'SandboxComp/messageCenterStateContainer',
    UniversalControlStateContainer: 'Controls/universalControl/universalControlStateContainer',
    WeatherControlStateContainer: 'Controls/weatherControl/weatherControlStateContainer',
    InfoViewControlStateContainer: 'Controls/infoViewControl/infoViewControlStateContainer',
    TextStateControlStateContainer: 'Controls/textStateControl/textStateControlStateContainer',
    SequentialControlStateContainer: 'Controls/sequentialControl/sequentialControlStateContainer',
    SwitchControlStateContainer: 'Controls/switchControl/switchControlStateContainer',
    PushbuttonControlStateContainer: 'Controls/pushbuttonControl/pushbuttonControlStateContainer',
    LoadManagerControlStateContainer: 'Controls/loadManagerControl/loadManagerControlStateContainer',
    PulseAtControlStateContainer: 'Controls/pulseAtControl/pulseAtControlStateContainer',
    EnergyManagerControlStateContainer: 'Controls/energyManagerControl/energyManagerControlStateContainer',
    EnergyManager2ControlStateContainer: 'Controls/energyManager2Control/energyManager2ControlStateContainer',
    AcControlStateContainer: 'Controls/acControl/acControlStateContainer',
    SpotPriceOptimizerControlStateContainer: 'Controls/spotPriceOptimizerControl/spotPriceOptimizerControlStateContainer',
    EnergyFlowMonitorControlStateContainer: 'Controls/energyFlowMonitorControl/energyFlowMonitorControlStateContainer',
    DimmerControlStateContainer: 'Controls/dimmerControl/dimmerControlStateContainer',
    JalousieControlStateContainer: 'Controls/jalousieControl/jalousieControlStateContainer',
    JalousieCentralControlStateContainer: 'Controls/jalousieCentralControl/jalousieCentralControlStateContainer',
    GateControlStateContainer: 'Controls/gateControl/gateControlStateContainer',
    GateCentralControlStateContainer: 'Controls/gateCentralControl/gateCentralControlStateContainer',
    TrackerControlStateContainer: 'Controls/trackerControl/trackerControlStateContainer',
    RadioControlStateContainer: 'Controls/radioControl/radioControlStateContainer',
    UpDownLeftRightAnalogControlStateContainer: 'Controls/upDownLeftRightControl/upDownLeftRightAnalogControlStateContainer',
    ValueSelectorControlStateContainer: 'Controls/valueSelectorControl/valueSelectorControlStateContainer',
    SliderControlStateContainer: 'Controls/sliderControl/sliderControlStateContainer',
    AlarmControlStateContainer: 'Controls/alarmControl/alarmControlStateContainer',
    AlarmCentralControlStateContainer: 'Controls/alarmCentralControl/alarmCentralControlStateContainer',
    SmokeAlarmControlStateContainer: 'Controls/smokeAlarmControl/smokeAlarmControlStateContainer',
    LightControlStateContainer: 'Controls/lightControl/lightControlStateContainer',
    LightCentralControlStateContainer: 'Controls/lightCentralControl/lightCentralControlStateContainer',
    LightV2ControlStateContainer: 'Controls/lightV2Control/lightV2ControlStateContainer',
    LightsceneRGBControlStateContainer: 'Controls/lightsceneRGBControl/lightsceneRGBControlStateContainer',
    ColorPickerControlStateContainer: 'Controls/colorPickerControl/colorPickerControlStateContainer',
    ColorPickerV2ControlStateContainer: 'Controls/colorPickerV2Control/colorPickerV2ControlStateContainer',
    DaytimerControlStateContainerBase: 'Controls/daytimerControl/daytimerControlStateContainerBase',
    DaytimerControlStateContainer: 'Controls/daytimerControl/daytimerControlStateContainer',
    IntercomControlStateContainer: 'Controls/intercomControl/intercomControlStateContainer',
    MeterControlStateContainer: 'Controls/meterControl/meterControlStateContainer',
    MeterV2ControlStateContainer: 'Controls/meterV2Control/meterV2ControlStateContainer',
    HeatmixerControlStateContainer: 'Controls/heatmixerControl/heatmixerControlStateContainer',
    RemoteControlStateContainer: 'Controls/remoteControl/remoteControlStateContainer',
    SaunaControlStateContainer: 'Controls/saunaControl/saunaControlStateContainer',
    IRoomControlStateContainer: 'Controls/iRoomControl/iRoomControlStateContainer',
    IRCDaytimerControlStateContainer: 'Controls/iRoomControl/content/ircDaytimerControl/ircDaytimerControlStateContainer',
    IRoomControllerV2ControlStateContainer: 'Controls/iRoomControllerV2/iRoomV2ControlStateContainer',
    IRCV2DaytimerControlStateContainer: 'Controls/iRoomControllerV2/content/ircv2DaytimerControl/ircv2DaytimerControlStateContainer',
    IRoomControllerV2021ControlStateContainer: 'Controls/iRoomControllerV2021/iRoomV2021ControlStateContainer',
    TimedSwitchControlStateContainer: 'Controls/timedSwitchControl/timedSwitchControlStateContainer',
    FroniusControlStateContainer: 'Controls/froniusControl/froniusControlStateContainer',
    MailBoxControlStateContainer: 'Controls/mailBoxControl/mailBoxControlStateContainer',
    MediaClientControlStateContainer: 'Controls/mediaClientControl/mediaClientControlStateContainer',
    HourcounterControlStateContainer: 'Controls/hourcounterControl/hourcounterControlStateContainer',
    AudioZoneControlStateContainer: 'Controls/audioZoneControl/audioZoneControlStateContainer',
    AudioZoneV2ControlStateContainer: 'Controls/audioZoneV2Control/audioZoneV2ControlStateContainer',
    AudioZoneCentralControlStateContainer: 'Controls/audioZoneCentralControl/audioZoneCentralControlStateContainer',
    AlarmClockControlStateContainer: 'Controls/alarmClockControl/alarmClockControlStateContainer',
    CarChargerControlStateContainer: 'Controls/carChargerControl/carChargerControlStateContainer',
    PoolControlStateContainer: 'Controls/poolControl/poolControlStateContainer',
    PoolDaytimerControlStateContainer: 'Controls/poolControl/content/poolDaytimerControl/poolDaytimerControlStateContainer',
    NfcCodeTouchControlStateContainer: 'Controls/nfcCodeTouchControl/nfcCodeTouchControlStateContainer',
    WindowMonitorControlStateContainer: 'Controls/windowMonitorControl/windowMonitorControlStateContainer',
    StatusMonitorControlStateContainer: 'Controls/statusMonitorControl/statusMonitorControlStateContainer',
    
    SteakControlStateContainer: 'Controls/steakControl/steakControlStateContainer',
    VentilationControlStateContainer: 'Controls/ventilationControl/ventilationControlStateContainer',
    ClimateControlStateContainer: 'Controls/climateControl/climateControlStateContainer',
    ClimateUsControlStateContainer: 'Controls/climateUsControl/climateUsControlStateContainer',
    SolarPumpControlStateContainer: 'Controls/solarPumpControl/solarPumpControlStateContainer',
    WindowControlStateContainer: 'Controls/windowControl/windowControlStateContainer',
    CentralWindowControlStateContainer: 'Controls/windowCentralControl/windowCentralControlStateContainer',
    TextInputControlStateContainer: 'Controls/textInputControl/textInputControlStateContainer',
    AalSmartAlarmControlStateContainer: 'Controls/aalSmartAlarmControl/aalSmartAlarmControlStateContainer',
    SystemSchemeControlStateContainer: 'Controls/systemSchemeControl/systemSchemeControlStateContainer',
    AalEmergencyControlStateContainer: 'Controls/aalEmergencyControl/aalEmergencyControlStateContainer',
    PresenceDetectorControlStateContainer: 'Controls/presenceDetectorControl/presenceDetectorControlStateContainer',
    IrrigationControlStateContainer: 'Controls/irrigationControl/irrigationControlStateContainer',
    AtheneControlStateContainer: 'Controls/atheneControl/atheneControlStateContainer',
    PowerUnitControlStateContainer: 'Controls/powerUnitControl/powerUnitControlStateContainer',
    Wallbox2ControlStateContainer: 'Controls/wallbox2Control/wallbox2ControlStateContainer',
    WallboxManagerControlStateContainer: 'Controls/wallboxManagerControl/wallboxManagerControlStateContainer',
    ReactTestScreen: 'GUI/ActiveMiniserver/menu/reactTest/reactTestScreen',
    GlobalCache: 'UtilityComp/IndexedDB/globalCacheDB.js',
    AudioZoneImageCache: 'UtilityComp/IndexedDB/AudioZoneImageCache.js',
}

module.exports = modules;
